<div class="base-container">
  <div *ngIf="false" class="sub-headers">
    <div class="sub-header-options" [ngClass]="{'active': selectedTab === 'callhistory'}" (click)="switchSubTabs('callhistory')">
      <span>{{'call-history.title' | translate}}</span>
    </div>
    <div class="sub-header-options" [ngClass]="{'active': selectedTab === 'voicemail'}" disabled = true>
      <span>{{'Voice Mail'}}</span>
    </div>
  </div>
  <div class="header-options-container">
    <p *ngIf="!isMobile">
        {{titleCallHistory}}
    </p>
    <div class="switch">
      <div class="outer">
        <div class="voicemail" *ngIf="isVoicemailWaitingIndicator; else novoicemail">
          <span (click)="selectDirectory('all')" [ngClass]="{ active: selectedFilter === 'all' }">
            {{ 'call-history.all' | translate }}
          </span>
          <span (click)="selectDirectory('missed')" [ngClass]="{ active: selectedFilter === 'missed' }">
            {{ 'call-history.missed' | translate }}
          </span>
          <span (click)="selectDirectory('voicemail')" [ngClass]="{ active: selectedFilter === 'voicemail' }">
            {{ 'call-history.voicemails' | translate }}<span *ngIf="voicemailIndicator" class="voicemail-badge"></span>
          </span>
        </div>
        <ng-template #novoicemail>
          <div class="novoicemail">
            <span (click)="selectDirectory('all')" [ngClass]="{ active: selectedFilter === 'all' }">
              {{ 'call-history.all' | translate }}
            </span>
            <span (click)="selectDirectory('missed')" [ngClass]="{ active: selectedFilter === 'missed' }">
              {{ 'call-history.missed' | translate }}
            </span>
          </div>
        </ng-template>
      </div>
    </div>
    <p></p>
    <div class="histroy-search-box" [style.display]="(selectedFilter !== 'voicemail' && callRecords.length > 0) ? null : 'none'">
      <img src="assets/icons/search-bold.svg">
      <input type="text" placeholder="Search all calls" #searchText>
    </div>
  </div>
  <div class="call-history-container" *ngIf="selectedTab === 'callhistory'">
    <div *ngIf="selectedFilter === 'voicemail'" class="call-history-wrapper" >
      <app-voicemail [vmPilotNumber]="vmPilotNumber" [voicemailIndicator]="voicemailIndicator"></app-voicemail>
    </div>
    <div
      *ngIf="!showLoader ; else wait"
      class="call-history-wrapper"
      infinite-scroll
      (scrolled)="onScrollDown()"
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="2"
      [infiniteScrollThrottle]="0"
      [scrollWindow]="false"
    >
      <ng-container *ngFor="let call of callRecords | filtercalls: selectedFilter : searchText?.value">
        <div class="call-record" [ngClass]="{ missedCall: call.isMissedCall }" >
          <div class="call-record-row" [ngClass]="{'active': selectedRowStyle === call.callId}" (click)="showHideButtons(call)">
            <div class="avatar" [ngStyle]="{'background-color': getBackgroundColor(call)}">
              <img *ngIf="call?.avatars || call?.initials === 'UX' || call?.initials === 'XX' ; else initials" [src]="getAvatar(call)" />
              <ng-template #initials
                ><span>{{ getInitials(call) }}</span>
              </ng-template>
              <span class="presence-indicator" *ngIf="getContactPresence(call) !== 'PresenceUnknown'"
                ><div
                  presenceStatus
                  [presence]="getContactPresence(call)"
                  [activity]="getContactActivity(call)"
                  class="presence"
                  [tooltip]="getContactActivity(call)"
                ></div
              ></span>
            </div>
            <div>
              <p>{{ getCallerName(call) }}</p>
              <p *ngIf="checkExtension(call) !== ''" >ext. {{ getExtension(call) }}</p>
            </div>
          </div>
          <div class="call-record-row" [ngClass]="{'active': selectedRowStyle === call.callId}" (click)="showHideButtons(call)">
            <ng-container [ngTemplateOutlet]="callDateTime" [ngTemplateOutletContext]="{call: call}"></ng-container>
            <ng-container [ngTemplateOutlet]="callTypeIcon" [ngTemplateOutletContext]="{call: call}"></ng-container>
            <ng-container *ngIf="!isMobile" [ngTemplateOutlet]="hiddenHoverBtn" [ngTemplateOutletContext]="{call: call}"></ng-container>
          </div>
          <ng-container *ngIf="isMobile" [ngTemplateOutlet]="hiddenHoverBtn" [ngTemplateOutletContext]="{call: call}"></ng-container>
        </div>
      </ng-container>
      <div *ngIf="scrollLoading" class="call-record loader-wrapped">
        <app-loading [loaderType]="'listLoader'"></app-loading>
      </div>
    </div>
    <div *ngIf="!showLoader && selectedFilter === 'missed'" class="norec-message">
      <p>{{'call-history.history-empty-missed' | translate}}</p>
    </div>
    <div *ngIf="!showLoader && selectedFilter === 'all'" class="norec-message">
      <p>{{'call-history.history-empty' | translate}}</p>
    </div>
  </div>
  <ng-template #wait>
    <ng-container *ngIf="showLoader; else emptyMessage">
      <app-loading [loaderType]="'listLoader'"></app-loading>
    </ng-container>
  </ng-template>
  <ng-template #emptyMessage>
    <div class="history-empty-message">
      <div><span></span></div>
      <div>
        <span>{{ 'call-history.history-empty' | translate }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #callTypeIcon let-call='call'>
    <div>
      <mi-svg
        *ngIf="call.isInboundCall"
        [fileSrc]="'assets/icons/one-app-inbound-call.svg'"
        [fillColor]="themeSvc.themeProperties['--mi-dark-grey']"
        [pixelSize]="18"
        [destroyTimeout]="1000"
        [attr.aria-label]="'incomingCall' | translate"
      ></mi-svg>
      <mi-svg
        *ngIf="call.direction === 'outbound'"
        [fileSrc]="'assets/icons/one-app-outbound-call.svg'"
        [fillColor]="themeSvc.themeProperties['--mi-dark-grey']"
        [pixelSize]="18"
        [destroyTimeout]="1000"
        [attr.aria-label]="'outgoingCall' | translate"
      ></mi-svg>

      <mi-svg
        *ngIf="call.isMissedCall"
        [fileSrc]="'assets/icons/one-app-missed-call.svg'"
        [fillColor]="themeSvc.themeProperties['--mi-error']"
        [pixelSize]="18"
        [destroyTimeout]="1000"
        [attr.aria-label]="'missedCall' | translate"
      ></mi-svg>
    </div>
  </ng-template>
  <ng-template #callDateTime let-call='call'>
    <div class="time-container">
      <div class="mini-banner" *ngIf="!isMobile && call.isMissedCall">{{ 'call-history.missed' | translate }}</div>
      <div class="call-time"><pre>{{ getLocalDate(call?.createdOn) }}</pre></div>
    </div>
  </ng-template>
  <ng-template #hiddenHoverBtn let-call='call'>
    <div class="hidden-hover-btn" [ngClass]="{'active': selectedRowStyle ===  call.callId}">
      <button class="delete" (click)="deleteCallFromHistory(call)" [disabled]="call.isActionInProgress">
        <mi-svg
          [iconName]="svgIcons.TRASH"
          [fillColor]="themeSvc.themeProperties['--mi-primary-color']"
          [pixelSize]="18"
        ></mi-svg>
      </button>
      <button class="call" (click)="makeCallFromHistory(call)" [disabled]="call.isActionInProgress">
        <mi-svg
          [iconName]="svgIcons.CALL_ICON"
          [fillColor]="themeSvc.themeProperties['--mi-primary-color']"
          [pixelSize]="18"
        ></mi-svg>
      </button>
      <button
        *ngIf="isAddedtoSpeedDial(call)"
        class="favorite" (click)="addRemoveSpeedDial(call)"
        [disabled]="call.isActionInProgress">
        <mi-svg [fileSrc]="'assets/icons/favorite.svg'" [pixelSize]="18"></mi-svg>
      </button>
      <button
        *ngIf="!isAddedtoSpeedDial(call) && canAddtoSpeedDial(call) && speedDialsLimit"
        class="not-favorite"
        (click)="addRemoveSpeedDial(call)"
        [disabled]="call.isActionInProgress">
        <mi-svg [fileSrc]="'assets/icons/nonfavorite.svg'" [pixelSize]="18"></mi-svg>
      </button>
    </div>
  </ng-template>
</div>