import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentLoginUrlMap } from 'src/app/helpers/loginghelper-component-map';
import { ContactService } from 'src/app/services/contact.service';
import { FeatureControlService } from 'src/app/services/feature-control.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-login-helper',
  templateUrl: './login-helper.component.html',
  styleUrls: ['./login-helper.component.less'],
})
export class LoginHelperComponent implements OnInit, OnDestroy {
  error = '';
  signingIn = false;
  signInFail = false;

  constructor(
    private contactService: ContactService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fcs: FeatureControlService) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.contactService.setAuthProviderForTeams(true);
    } finally {
      const url = ComponentLoginUrlMap.getComponentUrl(this.activatedRoute.snapshot.url[0].path);
      LoggingService.info(`[LoginHelperComponent]: reditecting to component: ${url}`);
      this.router.navigate([url]);
    }
  }

  ngOnDestroy(): void {
  }

  async getMyDetails(): Promise<void> {
    this.signingIn = true;
    try {
      const user = await this.contactService.getMyDetails();
      if ( user && user.mail) {
        await this.fcs.init(user.mail);
      }
      await this.contactService.setAuthProviderForTeams(false);
    } catch (error) {
      this.signInFail = true;
      this.error = 'Failed to get access token';
      LoggingService.error('[LoginHelperComponent]: Error in getMyDetails- ' + this.error);
    } finally {
      this.signingIn = false;
    }
  }

  tryAgain(): void {
    this.signingIn = false;
    this.signInFail = false;

    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }
}
