import { Injectable } from '@angular/core';
import { SHA256 } from 'crypto-js';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';
import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';
import { CallHistoryService } from './call-history.service';
import { pbxType } from 'src/app/common/constants';

const MITEL_CUST_CL_ACC_ID = '@mitel/CLAccountId';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  private ldClientId: string;
  private user: LDClient.LDUser;
  public ldClient: LDClient.LDClient;
  pbx: string;

  private flags: LDFlagSet;
  flagChange$: BehaviorSubject<LDFlagSet> = new BehaviorSubject<object>({});
  loaded$: Subject<object> = new Subject<object>();
  get flagDetailsObs(): BehaviorSubject<LDFlagSet>{
    return this.flagChange$;
  }

  constructor(
    private clService: CloudAuthenticationService,
    private authService: AuthService,
    private callHistorySvc: CallHistoryService,
    ) {}

  init(userName: string): void {
    this.ldClientId =  environment.ldClientId;
    const hashedId = SHA256(userName.toLowerCase()).toString();
    this.user = {
      key: hashedId,
      custom: {
        usernameDomain: this.getUserDomain(userName),
        azureUserId: this.authService.getUserId(),
      }
    };
    this.ldClient = LDClient.initialize(this.ldClientId, this.user);
    this.ldClient.waitForInitialization().catch(ex => {
      LoggingService.error('[launch-darkly.service.init()]', ex);
      this.loaded$.next();
    });

    this.ldClient.on('change', flags => {
      this.updateFlags(flags);
      LoggingService.info('[launch-darkly.service: ldclient.on.change]: fetch new LD flags/values', flags);
    });

    this.ldClient.on('ready', () => {
      this.setFlags();
      LoggingService.info('[launch-darkly.service: ldclient.on.ready]: fetch LD flags');
      this.loaded$.next();
    });

    this.clService.isLoggedIn.subscribe((value: boolean) => {
      if (value === true){
        setTimeout(async () => {
          this.pbx = await this.getAccountPBX(localStorage.getItem(MITEL_CUST_CL_ACC_ID));
          this.checkFeatureFlagForUser(hashedId);
        }, 1000);
      }
    });
  }

  close(): void {
    // logout user and close launch darkly connection
    this.ldClient.close();
  }

  private updateFlags(changedFlags): void {
    for (const key of Object.keys(changedFlags)) {
      this.flags[key] = changedFlags[key].current;
    }
    this.flagChange$.next(this.flags);
  }

  setFlags(): void {
    // initialize flags
    this.flags = this.ldClient.allFlags();
    LoggingService.info('LD flags', this.flags);
    this.flagChange$.next(this.flags);
  }

  getFlags(): Observable<object> {
    return this.flagDetailsObs;
  }

  private getUserDomain(userName: string): string {
    const ids = userName.split('@');
    return ids.length === 2 ? ids[1] : '';
  }

  public async getAccountPBX(accId): Promise<string> {
    LoggingService.info('Launch Darkly Service.Service]: calling accountTag api', accId);
    const result = await this.callHistorySvc.getAccountTag(accId, 'products');
    LoggingService.info('[Launch Darkly Service]:getAccountPBX', result);
    if (result.some(i => i === pbxType.MiVO5000)) {
      return pbxType.MiVO5000;
    } else if (result.some(i => i === pbxType.MXONE)) {
      return  pbxType.MXONE;
    } else if (result.some(i => i === pbxType.MiVO400)) {
      return pbxType.MiVO400;
    } else if (result.some(i => i === pbxType.MiVB)) {
      return pbxType.MiVB;
    }
    return null;
  }

  private checkFeatureFlagForUser(hashedId): void {
    this.clService.whoAmI().then(async (claims) => {
      const [id, email, accountId, iss] = ['userId', 'email', 'accountId', 'iss'];
      this.user = {
        key: hashedId,
        custom: {
          usernameDomain: this.getUserDomain(claims[email]),
          azureUserId: this.authService.getUserId(),
          userId: claims[id],
          accountId: claims[accountId],
          region: this.getCloudRegion(claims[iss]),
          pbxType: this.pbx,
        }
      };
      this.ldClient.identify(this.user, hashedId).then(() => this.setFlags());
      LoggingService.info('[launch-darkly.service: ldclient.identify]: changing user context');
    });
  }

  public getCloudRegion(regionUrl: string): string {
    const regex = /\.([a-z]{2})\-/;
    return regionUrl.match(regex)[1];
  }
}
