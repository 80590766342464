import { Injectable } from '@angular/core';
import * as fetchIntercept from 'fetch-intercept';
import { TeamsAuthService } from '../services/teams-auth.service';
import { LoggingService } from '../services/logging.service';
import { CloudAuthenticationService } from '../services/cloud-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class FetchInterceptorService {
  constructor(private teamsAuthService: TeamsAuthService, private clAuthService: CloudAuthenticationService) {}

  badRequestHandler(error: any): void {
    const errorCodes = (error?.error_codes || []) as number[];
    // Refer this for arror code https://login.microsoftonline.com/error?code=700081
    if (errorCodes.includes(700081)) {
      LoggingService.error('[FetchInterceptorService]: refresh token expired redirecting to login page');
      this.teamsAuthService.resetAuthState();
    }
  }

  responseHandler(response: Response): Response {
    switch (response.status) {
      case 400:
        response.clone().json().then(this.badRequestHandler);
        break;
      case 401:
        if ( this.isCloudLinkUrl(response)) {
          this.clAuthService.refreshToken();
          return;
        }
        LoggingService.error('[FetchInterceptorService]: access token expired redirecting to login page');
        this.teamsAuthService.resetAuthState();
        break;
    }
    return response;
  }

  init(): void {
    fetchIntercept.register({
      response: this.responseHandler.bind(this),
    });
  }

  isCloudLinkUrl(response: Response): boolean {
    const regExUrl = /https:\/\/\w+(.dev)?.api.mitel.io/gm;
    return regExUrl.test(response.url);
  }
}
