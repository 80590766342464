// Declarations for on/off feature flags
export const FeatureFlags = {
  ALLOW_CL_LOGIN: 'allow-cl-login',
  AlLOW_CALL_HISTORY: 'allow-call-history',
  ALLOW_VOICEMAIL_WAITING_INDICATOR: 'allow-voicemail-waiting-indicator',
};

// On/Off toggles available in Launch Darkly Service - not all FeatureFlags supported in LD
export const LdFeatureFlags = {
  LD_ALLOW_CL_LOGIN: 'allow-cloud-link-login',
  LD_AlLOW_CALL_HISTORY: 'allow-call-history',
  LD_ALLOW_VOICEMAIL_WAITING_INDICATOR: 'allow-voicemail-waiting-indicator',
};

interface IFeatureKey {
  readonly featureFlag: string;
  readonly ldKey: string;
  readonly default: boolean; // use this for to handle legacy type behaviors of features that might not be policy/LD controlled
}

export const FeatureKeyMap: IFeatureKey[] = [
  {
    featureFlag: FeatureFlags.ALLOW_CL_LOGIN,
    ldKey: LdFeatureFlags.LD_ALLOW_CL_LOGIN,
    default: false
  },
  {
    featureFlag: FeatureFlags.AlLOW_CALL_HISTORY,
    ldKey: LdFeatureFlags.LD_AlLOW_CALL_HISTORY,
    default: false
  },
  {
    featureFlag: FeatureFlags.ALLOW_VOICEMAIL_WAITING_INDICATOR,
    ldKey: LdFeatureFlags.LD_ALLOW_VOICEMAIL_WAITING_INDICATOR,
    default: false
  }
];

export class FeatureFlagUtil {
  static featureKeyFromLdKey(ldKey: string): string {
    const featureKey = FeatureKeyMap.find(feature => feature.ldKey === ldKey);
    return featureKey ? featureKey.featureFlag : undefined;
  }
}
