import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { LaunchDarklyService } from './launch-darkly.service';
import { FeatureFlags, FeatureFlagUtil, FeatureKeyMap } from '../model/feature-flags';
import { LoggingService } from './logging.service';

class FeatureState {
  ldEnabled: boolean;
  enabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureControlService {
  private featureStates: Map<string, FeatureState>;
  private initialized = false;

  constructor(
    private ldService: LaunchDarklyService,
  ) {
    this.featureStates = new Map();
  }

  async init(userName: string): Promise<void> {
    LoggingService.info('[feature-control.service.init()]: init start - initialized value)', this.initialized);
    if (!this.initialized) {
      try {
        // Create the featureStates default first
        FeatureKeyMap.forEach(f => {
          this.featureStates.set(f.featureFlag, {
            ldEnabled: false,
            enabled: f.default,
          });
        });
        await this.fetchLaunchDarklyFlags(userName);
      } catch (ex) {
        LoggingService.error('[feature-control.service.init()]:', ex);
        throw(ex);
      }
      await this.setupFeatureFlags();

      LoggingService.info('[feature-control.service.init()]: init complete)');
      this.initialized = true;
    }
  }

  public isFeatureEnabled(key: string): boolean {
    let isEnabled = false;
    if (this.initialized) {
      isEnabled = this.getFeatureEnableStatus(key);
    }
    return isEnabled;
  }

  private getFeatureEnableStatus(key: string): boolean {
    let isEnabled = false;
    switch (key) {
      case FeatureFlags.ALLOW_CL_LOGIN:
        isEnabled = this.isClLoginEnabled();
        break;
      default:
        const feature = this.featureStates.get(key);
        if (feature) {
          isEnabled = feature.enabled;
        }
    }
    return isEnabled;
  }

  private isClLoginEnabled(): boolean {
    const featureInfo = this.featureStates.get(FeatureFlags.ALLOW_CL_LOGIN);
    if (featureInfo && featureInfo.enabled) {
      return true;
    }
    return false;
  }

  private async fetchLaunchDarklyFlags(userName: string): Promise<void> {
    this.ldService.init(userName);
    await this.ldService.loaded$.pipe(take(1)).toPromise();
    const ldFlags = await this.ldService
      .getFlags()
      .pipe(take(1))
      .toPromise();
    if (ldFlags) {
      for (const key of Object.keys(ldFlags)) {
        const featureIndex = FeatureFlagUtil.featureKeyFromLdKey(key);
        if (featureIndex) {
          const mapEntry = this.featureStates.get(featureIndex);
          if (mapEntry) {
            mapEntry.ldEnabled = ldFlags[key];
          }
        }
      }
    }
    LoggingService.info('[feature-control.service.fetchLaunchDarklyFlags()]: fetch LD flags - complete');
  }

  private async setupFeatureFlags(): Promise<void> {
    LoggingService.info('[feature-control.service.setupFeatureFlags()]: setting up feature flags');
    for (const feature of this.featureStates.values()) {
      feature.enabled = feature.ldEnabled;
    }
  }

}
