import { IEnvironment } from 'src/app/interfaces/environment';

export const environment = {
  production: true,
  ldClientId: '6245c05e90fd281570b02b36',
  azureAD: {
    clientId: 'bd058f85-2ba7-47fe-bc12-1f8061beacbc',
  },
  awsAmplify: {
    identityPoolId: 'us-east-1:5631e654-b278-490b-9617-42d366edc35d',
    appId: '6fb0a35af65849a8ab889af1b701e2d0',
    region: 'us-east-1',
  },
  cloudlink: {
    clientId: '3ab0f7d2e8554bfe961d2b1f5bce32e0',
    authUrl: 'https://auth.mitel.io/authorize',
    tokenUrl: 'https://authentication.api.mitel.io/2017-09-01',
    mediaUrl: 'https://media.api.mitel.io/2017-09-01',
    adminUrl: 'https://admin.api.mitel.io/2017-09-01',
    isDev: false,
  },
} as IEnvironment;
